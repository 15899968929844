import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { IoIosCloseCircle } from "react-icons/io";

const products = [
  {
    id: 1,
    type: 'carousel',
    name: '100 cm Beyaz',
    images: [
      '/kategoriler/televizyonunitesi/100cmbeyaz/100cmbeyaz1.JPG',
      '/kategoriler/televizyonunitesi/100cmbeyaz/100cmbeyaz2.jpg'
    ],
    detailsUrl: 'https://wa.me/905534561074'
  },
  {
    id: 2,
    type: 'carousel',
    name: '200 cm Beyaz',
    images: [
      '/kategoriler/televizyonunitesi/200cmbeyaz/200cmbeyaz1.JPG',
      '/kategoriler/televizyonunitesi/200cmbeyaz/200cmbeyaz2.JPG',
      '/kategoriler/televizyonunitesi/200cmbeyaz/200cmbeyaz3.JPG'
    ],
    detailsUrl: 'https://wa.me/905534561074'
  },
  {
    id: 3,
    type: 'carousel',
    name: 'Ahşap Ünite',
    images: [
      '/kategoriler/televizyonunitesi/ahsapunite/ahsapunite1.JPG',
      '/kategoriler/televizyonunitesi/ahsapunite/ahsapunite2.JPG',
      '/kategoriler/televizyonunitesi/ahsapunite/ahsapunite3.JPG',
      '/kategoriler/televizyonunitesi/ahsapunite/ahsapunite4.JPG'
    ],
    detailsUrl: 'https://wa.me/905534561074'
  },
  {
    id: 4,
    type: 'carousel',
    name: 'Beyaz Hazeranli',
    images: [
      '/kategoriler/televizyonunitesi/beyazhazeranli/beyazhazeranli1.JPG',
      '/kategoriler/televizyonunitesi/beyazhazeranli/beyazhazeranli2.JPG',
      '/kategoriler/televizyonunitesi/beyazhazeranli/beyazhazeranli3.JPG'
    ],
    detailsUrl: 'https://wa.me/905534561074'
  },
  {
    id: 5,
    type: 'carousel',
    name: 'Tasarım1',
    images: [
      '/kategoriler/televizyonunitesi/digertasarimuniteler/urun1.PNG',
      '/kategoriler/televizyonunitesi/digertasarimuniteler/urun12.PNG'
    ],
    detailsUrl: 'https://wa.me/905534561074'
  },
  {
    id: 6,
    type: 'carousel',
    name: 'Tasarım2',
    images: [
      '/kategoriler/televizyonunitesi/digertasarimuniteler/urun2.PNG',
      '/kategoriler/televizyonunitesi/digertasarimuniteler/urun22.PNG'
    ],
    detailsUrl: 'https://wa.me/905534561074'
  },
  {
    id: 7,
    type: 'carousel',
    name: 'Tasarım3',
    images: [
      '/kategoriler/televizyonunitesi/digertasarimuniteler/urun3.PNG',
      '/kategoriler/televizyonunitesi/digertasarimuniteler/urun32.PNG'
    ],
    detailsUrl: 'https://wa.me/905534561074'
  },
  {
    id: 8,
    type: 'carousel',
    name: 'Dört Kapaklı Hazeran',
    images: [
      '/kategoriler/televizyonunitesi/dortkapaklihazeran/dortkapaklihazeran1.JPG',
      '/kategoriler/televizyonunitesi/dortkapaklihazeran/dortkapaklihazeran2.JPG',
      '/kategoriler/televizyonunitesi/dortkapaklihazeran/dortkapaklihazeran3.JPG'
    ],
    detailsUrl: 'https://wa.me/905534561074'
  },
  {
    id: 9,
    type: 'carousel',
    name: 'İki Kapaklı Hazeran',
    images: [
      '/kategoriler/televizyonunitesi/ikikapaklihazeran/ikikapaklihazeran1.JPG',
      '/kategoriler/televizyonunitesi/ikikapaklihazeran/ikikapaklihazeran2.JPG',
      '/kategoriler/televizyonunitesi/ikikapaklihazeran/ikikapaklihazeran3.JPG',
      '/kategoriler/televizyonunitesi/ikikapaklihazeran/ikikapaklihazeran4.JPG'
    ],
    detailsUrl: 'https://wa.me/905534561074'
  },
  {
    id: 10,
    type: 'carousel',
    name: 'Kubbeli',
    images: [
      '/kategoriler/televizyonunitesi/kubbeli/kubbeli1.JPG',
      '/kategoriler/televizyonunitesi/kubbeli/kubbeli2.JPG',
      '/kategoriler/televizyonunitesi/kubbeli/kubbeli3.JPG'
    ],
    detailsUrl: 'https://wa.me/905534561074'
  },
  {
    id: 11,
    type: 'carousel',
    name: 'Masif Ünite',
    images: [
      '/kategoriler/televizyonunitesi/masifunite/masifunite1.JPG',
      '/kategoriler/televizyonunitesi/masifunite/masifunite2.JPG',
      '/kategoriler/televizyonunitesi/masifunite/masifunite3.JPG'
    ],
    detailsUrl: 'https://wa.me/905534561074'
  },
  {
    id: 12,
    type: 'carousel',
    name: 'Oval Hazeranlı',
    images: [
      '/kategoriler/televizyonunitesi/ovalhazeranli/ovalhazeranli1.JPG',
      '/kategoriler/televizyonunitesi/ovalhazeranli/ovalhazeranli2.JPG',
      '/kategoriler/televizyonunitesi/ovalhazeranli/ovalhazeranli3.JPG'
    ],
    detailsUrl: 'https://wa.me/905534561074'
  },
  {
    id: 13,
    type: 'carousel',
    name: 'Oval Kapaklı',
    images: [
      '/kategoriler/televizyonunitesi/ovalkapakli/ovalkapakli1.JPG',
      '/kategoriler/televizyonunitesi/ovalkapakli/ovalkapakli2.JPG',
      '/kategoriler/televizyonunitesi/ovalkapakli/ovalkapakli3.JPG'
    ],
    detailsUrl: 'https://wa.me/905534561074'
  },
  {
    id: 14,
    type: 'carousel',
    name: 'Ters Kubbeli',
    images: [
      '/kategoriler/televizyonunitesi/terskubbeli/terskubbeli1.JPG',
      '/kategoriler/televizyonunitesi/terskubbeli/terskubbeli2.JPG',
      '/kategoriler/televizyonunitesi/terskubbeli/terskubbeli3.JPG'
    ],
    detailsUrl: 'https://wa.me/905534561074'
  },
  
  // Daha fazla ürün ekleyin
];

const TelevizyonUniteleri = () => {
    const [activeImage, setActiveImage] = useState(null);

    const openModal = (image) => {
      setActiveImage(image);
    };
  
    const closeModal = () => {
      setActiveImage(null);
    };

    const getDetailsUrl = (product) => {
      const message = `Merhaba, "${product.name}" isimli ürününüz için size ulaşmak istiyorum.`;
      return `https://wa.me/905534561074?text=${encodeURIComponent(message)}`;
    };
  
    return (
      <div className='min-h-[70vh]'>
          <h1 className="w-full text-center text-2xl font-bold my-4">Televizyon Üniteleri</h1>
          <div className="flex flex-wrap justify-center gap-4 p-4 ">
          {products.map(product => (
          <div key={product.id} className="w-64 bg-white h-fit shadow-lg rounded-lg overflow-hidden">
            {product.type === 'single' ? (
              <div>
                <img src={product.images[0]} alt={product.name} className="w-full h-48 object-cover" />
                <div className="p-4">
                  <h2 className="text-lg font-semibold">{product.name}</h2>
                  <a href={getDetailsUrl(product)} target="_blank" rel="noopener noreferrer" className="mt-2 block text-center bg-green-500 text-white py-2 rounded">
                    Detaylı Bilgi için WhatsApp
                  </a>
                </div>
              </div>
            ) : (
              <div>
                <Swiper spaceBetween={50} slidesPerView={1} pagination={{ clickable: true }}>
                  {product.images.map((image, index) => (
                    <SwiperSlide key={index}>
                      <div className="relative" onClick={() => openModal(image)}>
                        <img src={image} alt={product.name} className="w-full h-48 object-cover cursor-pointer" />
                        <div className="absolute top-2 left-2 bg-black bg-opacity-50 text-white px-2 py-1 rounded-full">
                          {index + 1} / {product.images.length}
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="p-4">
                  <h2 className="text-lg font-semibold">{product.name}</h2>
                  <a href={getDetailsUrl(product)} target="_blank" rel="noopener noreferrer" className="mt-2 block text-center bg-green-500 text-white py-2 rounded">
                    Detaylı Bilgi için WhatsApp
                  </a>
                </div>
              </div>
            )}
          </div>
        ))}
          </div>
          <div className='min-h-[30vh] bg-white text-black content-center justify-center items-center p-16 text-lg font-semibold'>
            <p>Komple mdfden üretilmektedir. Özel ölçü ve renk üretimi yapılmakdadır.<br/>
120 cm- 135 cm- 160 cm- 180 cm- 200 cm- 225 cm
Boyutlarında seri üretimimiz bulunmaktadır.</p>
            <p>3CM YÜKSEKLİK <br/>38 CM DERİNLİK <br/>MDF KAPAKLAR <br/>GÖVDE MDF <br/>4 FARKLI AYAK MODELİ</p>
          </div>
          {activeImage && (
            <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-75 z-50" onClick={closeModal}>
              <div className="max-w-screen-md w-full max-h-screen-md p-4 bg-white rounded-lg overflow-hidden relative">
                <img src={activeImage} alt="Büyük Resim" className="w-full h-full object-contain" />
                <IoIosCloseCircle color="white" className='absolute top-4 right-4 h-16 w-16' onClick={closeModal}/>
              </div>
            </div>
          )}
      </div>
    );
  };

export default TelevizyonUniteleri;
