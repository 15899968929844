import React, { useState, useEffect } from 'react';
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

// Mobil ve masaüstü için farklı resimler
const imagesDesktop = [
  { "src": "/slider/slider1.jpg", "link": "/" },
  { "src": "/slider/slider2.jpg", "link": "/" },
  { "src": "/slider/slider3.jpg", "link": "/" },
  { "src": "/slider/slider4.jpg", "link": "/" },
  { "src": "/slider/slider5.jpg", "link": "/" },
  { "src": "/slider/slider6.jpg", "link": "/" },
  { "src": "/slider/slider7.jpg", "link": "/" },
  { "src": "/slider/slider8.jpg", "link": "/" }
];

const imagesMobile = [
  { "src": "/slider/mobil/slider1.jpg", "link": "/" },
  { "src": "/slider/mobil/slider2.jpg", "link": "/" },
  { "src": "/slider/mobil/slider3.jpg", "link": "/" },
  { "src": "/slider/mobil/slider4.jpg", "link": "/" },
  { "src": "/slider/mobil/slider5.jpg", "link": "/" },
  { "src": "/slider/mobil/slider6.jpg", "link": "/" },
  { "src": "/slider/mobil/slider7.jpg", "link": "/" },
  { "src": "/slider/mobil/slider8.jpg", "link": "/" }
];

const SimpleSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const isMobile = window.innerWidth < 768; // Mobil cihaz kontrolü
  const images = isMobile ? imagesMobile : imagesDesktop; // Boyuta göre resimleri seç

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  useEffect(() => {
    const intervalId = setInterval(goToNextSlide, 5000);
    return () => clearInterval(intervalId);
  }, [currentIndex]);

  return (
    <div className="relative w-full h-auto overflow-hidden">
      <button onClick={goToPrevSlide} className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white rounded-full p-2 focus:outline-none z-10">
        <FaArrowLeft />
      </button>
      <button onClick={goToNextSlide} className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white rounded-full p-2 focus:outline-none z-10">
        <FaArrowRight />
      </button>
      <div className="flex transition-transform duration-600 ease-in-out" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {images.map((image, index) => (
          <img
            key={index}
            src={image.src}
            alt={`Slide ${index + 1}`}
            className="w-full h-auto object-contain max-w-full max-h-full min-h-[300px] md:min-h-[500px]"
          />
        ))}
      </div>
    </div>
  );
};

export default SimpleSlider;
