import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-orange-100 text-black py-10 px-6">
      <div className="container mx-auto flex flex-wrap justify-around">
        {/* Logo Section */}
        <div className="flex items-center mb-4 w-full md:w-auto">
          <img src="/Logocircle.png" alt="Logo" className="h-32 w-32" />
        </div>
        {/* Contact Section */}
        <div className="mb-4 w-full md:w-auto">
          <h2 className="text-xl font-bold mb-2">İletişim</h2>
          <p className="mb-1">Telefon: (0553) 456-1074</p>
          <p>Adres: Gaziler Mahallesi 600. Sokak No: 17 MANİSA/Salihli</p>
        </div>
        {/* Recommended Categories Section */}
        <div className="mb-4 w-full md:w-auto">
          <h2 className="text-xl font-bold mb-2">Öne Çıkanlar</h2>
          <ul>
            <a href="/kategoriler/ahsap-kulplar"><li>Ahşap Kulp</li></a>
            <a href="/kategoriler/televizyon-uniteleri"><li>Televizyon Ünitesi</li></a>
            <a href="/kategoriler/agac-masalar"><li>Ağaç Masa</li></a>
          </ul>
        </div>
        {/* Social Media Section */}
        <div className="w-full md:w-auto">
          <h2 className="text-xl font-bold mb-2">Sosyal Medya</h2>
          <ul>
            <a href="https://www.instagram.com/lazzirhandle" target="_blank" rel="noopener noreferrer"><li>Instagram</li></a>
            <a href="https://wa.me/905534561074" target="_blank" rel="noopener noreferrer"><li>WhatsApp</li></a>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
