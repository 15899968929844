import React from 'react';

function FeaturedCategoryMain() {
  return (
    <div className="bg-white text-black" id="drops">
      <div className="relative text-center py-4 text-black">
        <h1 className="md:text-4xl text-2xl font-medium relative">ÖNE ÇIKAN KATEGORİLER
          <div className="border-t border-4 border-amber-600 mt-2 mx-auto w-72"></div> {/* Border sadece yazının altında */}
        </h1>
      </div>
      <div className="flex flex-wrap justify-center content-center items-center gap-4 py-16 text-center px-6">
        <a href="/kategoriler/ahsap-kulplar"><div className="md:w-[450px] md:h-[550px] w-[225px] h-[275px] bg-gradient-to-br bg-white shadow-2xl mr-16 md:mr-0 hover:bg-yellow-800 hover:text-white">
          <div className="flex justify-center content-center items-center w-full md:h-[450px] h-[225px]">
            <img alt="" src="/kategoriler/ahsapkulplar/dairekulplar/dairepremium/1.JPG" className="h-full w-full"></img>
          </div>
          <div className="flex flex-col md:py-6 text-center items-center">
            <span className="md:text-4xl">AHŞAP KULPLAR</span>
          </div>
        </div></a>
        <a href="/kategoriler/televizyon-uniteleri"><div className="md:w-[450px] md:h-[550px] w-[225px] h-[275px] bg-gradient-to-br bg-white shadow-2xl md:mt-24 ml-16 md:ml-0 hover:bg-yellow-800 hover:text-white">
          <div className="flex justify-center content-center items-center w-full md:h-[450px] h-[225px]">
            <img alt="" src="/kategoriler/televizyonunitesi/masifunite/masifunite1.JPG" className="h-full w-full"></img>
          </div>
          <div className="flex flex-col md:py-6 text-center items-center">
            <span className="md:text-4xl">TELEVİZYON ÜNİTELERİ</span>
          </div>
        </div></a>
        <a href="/kategoriler/agac-masalar"><div className="w-[450px] h-[550px] bg-gradient-to-br bg-white shadow-2xl hover:bg-yellow-800 hover:text-white hidden md:block">
          <div className="flex justify-center content-center items-center w-full h-[450px]">
            <img alt="" src="/kategoriler/agacmasa/dogalmasa/dogalmasa4.PNG" className="h-full w-full"></img>
          </div>
          <div className="flex flex-col py-6">
            <span className="text-4xl">AĞAÇ MASALAR</span>
          </div>
        </div></a>
      </div>
    </div>
  );
}

export default FeaturedCategoryMain;
