import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { IoIosCloseCircle } from "react-icons/io";

const products = [
  {
    id: 1,
    type: 'carousel',
    name: 'DİK KULP TEKLİ',
    images: [
      '/kategoriler/ahsapkulplar/teklikulplar/dikkulptekli/1.JPG',
      '/kategoriler/ahsapkulplar/teklikulplar/dikkulptekli/2.JPG'
    ],
    detailsUrl: 'https://wa.me/905534561074'
  },
  {
    id: 2,
    type: 'carousel',
    name: 'EĞİK KULP TEKLİ',
    images: [
      '/kategoriler/ahsapkulplar/teklikulplar/egiktekli/1.JPG',
      '/kategoriler/ahsapkulplar/teklikulplar/egiktekli/2.JPG'
    ],
    detailsUrl: 'https://wa.me/905534561074'
  },
  {
    id: 3,
    type: 'carousel',
    name: 'KARE KULP TEKLİ',
    images: [
      '/kategoriler/ahsapkulplar/teklikulplar/karetekli/3.JPG',
      '/kategoriler/ahsapkulplar/teklikulplar/karetekli/2.JPG',
      '/kategoriler/ahsapkulplar/teklikulplar/karetekli/1.JPG'
    ],
    detailsUrl: 'https://wa.me/905534561074'
  },
  {
    id: 4,
    type: 'carousel',
    name: 'KONİK KULP TEKLİ',
    images: [
      '/kategoriler/ahsapkulplar/teklikulplar/koniktekli/1.JPG',
      '/kategoriler/ahsapkulplar/teklikulplar/koniktekli/2.JPG',
      '/kategoriler/ahsapkulplar/teklikulplar/koniktekli/3.JPG'
    ],
    detailsUrl: 'https://wa.me/905534561074'
  },
  {
    id: 5,
    type: 'carousel',
    name: 'L KULP TEKLİ',
    images: [
      '/kategoriler/ahsapkulplar/teklikulplar/lkulptekli/1.JPG',
      '/kategoriler/ahsapkulplar/teklikulplar/lkulptekli/2.JPG',
      '/kategoriler/ahsapkulplar/teklikulplar/lkulptekli/3.JPG'
    ],
    detailsUrl: 'https://wa.me/905534561074'
  },
  {
    id: 6,
    type: 'carousel',
    name: 'TOP KULP TEKLİ',
    images: [
      '/kategoriler/ahsapkulplar/teklikulplar/toptekli/3.JPG',
      '/kategoriler/ahsapkulplar/teklikulplar/toptekli/2.JPG',
      '/kategoriler/ahsapkulplar/teklikulplar/toptekli/1.JPG'
    ],
    detailsUrl: 'https://wa.me/905534561074'
  },
  {
    id: 7,
    type: 'carousel',
    name: 'ÜÇGEN KULP TEKLİ',
    images: [
      '/kategoriler/ahsapkulplar/teklikulplar/ucgentekli/1.JPG',
      '/kategoriler/ahsapkulplar/teklikulplar/ucgentekli/2.JPG',
      '/kategoriler/ahsapkulplar/teklikulplar/ucgentekli/3.JPG'
    ],
    detailsUrl: 'https://wa.me/905534561074'
  },
  {
    id: 8,
    type: 'carousel',
    name: 'VİDALI KULP TEKLİ',
    images: [
      '/kategoriler/ahsapkulplar/teklikulplar/vidalitekli/2.JPG',
      '/kategoriler/ahsapkulplar/teklikulplar/vidalitekli/1.JPG'
    ],
    detailsUrl: 'https://wa.me/905534561074'
  },
  {
    id: 9,
    type: 'carousel',
    name: 'YARIM TOP TEKLİ',
    images: [
      '/kategoriler/ahsapkulplar/teklikulplar/yarimtoptekli/3.jpg',
      '/kategoriler/ahsapkulplar/teklikulplar/yarimtoptekli/2.JPG',
      '/kategoriler/ahsapkulplar/teklikulplar/yarimtoptekli/1.JPG'
    ],
    detailsUrl: 'https://wa.me/905534561074'
  },
  {
    id: 10,
    type: 'carousel',
    name: 'YUVARLAK EXTRA TEKLİ',
    images: [
      '/kategoriler/ahsapkulplar/teklikulplar/yuvarlakextratekli/1.JPG',
      '/kategoriler/ahsapkulplar/teklikulplar/yuvarlakextratekli/2.JPG'
    ],
    detailsUrl: 'https://wa.me/905534561074'
  },
  {
    id: 11,
    type: 'carousel',
    name: 'YUVARLAK KULP TEKLİ',
    images: [
      '/kategoriler/ahsapkulplar/teklikulplar/yuvarlaktekli/3.JPG',
      '/kategoriler/ahsapkulplar/teklikulplar/yuvarlaktekli/4.JPG',
      '/kategoriler/ahsapkulplar/teklikulplar/yuvarlaktekli/1.JPG',
      '/kategoriler/ahsapkulplar/teklikulplar/yuvarlaktekli/2.JPG'
    ],
    detailsUrl: 'https://wa.me/905534561074'
  },
];

const TekliAhsapKulp = () => {
    const [activeImage, setActiveImage] = useState(null);

    const openModal = (image) => {
      setActiveImage(image);
    };
  
    const closeModal = () => {
      setActiveImage(null);
    };

    const getDetailsUrl = (product) => {
      const message = `Merhaba, "${product.name}" isimli ürününüz için size ulaşmak istiyorum.`;
      return `https://wa.me/905534561074?text=${encodeURIComponent(message)}`;
    };
  
    return (
      <div className='min-h-[70vh]'>
          <h1 className="w-full text-center text-2xl font-bold my-4">Tekli Ahşap Kulplar</h1>
          <div className="flex flex-wrap justify-center gap-4 p-4 ">
          {products.map(product => (
          <div key={product.id} className="w-64 bg-white h-fit shadow-lg rounded-lg overflow-hidden">
            {product.type === 'single' ? (
              <div>
                <img src={product.images[0]} alt={product.name} className="w-full h-48 object-cover" />
                <div className="p-4">
                  <h2 className="text-lg font-semibold">{product.name}</h2>
                  <a href={getDetailsUrl(product)} target="_blank" rel="noopener noreferrer" className="mt-2 block text-center bg-green-500 text-white py-2 rounded">
                    Detaylı Bilgi için WhatsApp
                  </a>
                </div>
              </div>
            ) : (
              <div>
                <Swiper spaceBetween={50} slidesPerView={1} pagination={{ clickable: true }}>
                  {product.images.map((image, index) => (
                    <SwiperSlide key={index}>
                      <div className="relative" onClick={() => openModal(image)}>
                        <img src={image} alt={product.name} className="w-full h-48 object-cover cursor-pointer" />
                        <div className="absolute top-2 left-2 bg-black bg-opacity-50 text-white px-2 py-1 rounded-full">
                          {index + 1} / {product.images.length}
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="p-4">
                  <h2 className="text-lg font-semibold">{product.name}</h2>
                  <a href={getDetailsUrl(product)} target="_blank" rel="noopener noreferrer" className="mt-2 block text-center bg-green-500 text-white py-2 rounded">
                    Detaylı Bilgi için WhatsApp
                  </a>
                </div>
              </div>
            )}
          </div>
        ))}
          </div>
          <div className='min-h-[70vh] bg-white text-black content-center justify-center items-center p-16 text-lg font-semibold'>
            <p>Mağazamızda, 1. sınıf fırınlanmış ceviz, gürgen ve kestane ağacından üretilmiş ahşap kulplar bulunmaktadır. Tekli kulp ölçüleri olarak aşağıdaki boyutları sunuyoruz: 32 mm, 64 mm, 96 mm, 128 mm, 160 mm, 192 mm, 224 mm, 256 mm, 288 mm, 320 mm, 352 mm ve 384 mm. Özel ölçülerde de üretim yapmaktayız.</p>
            <p className='pt-4'>Ürettiğimiz kulplar %100 ahşaptır ve her ahşabın kendine özgü bir damar yapısı vardır. Size en uygun tonu gönderebilmek için kulpları açık ve koyu renk olarak iki sınıfa ayırıyoruz. Siparişlerinizde belirttiğiniz ton rengine en yakın olanları seçip gönderiyoruz (not: %100 aynısı olması ahşap için mümkün değildir). Kulp siparişi vermeden önce lütfen aşağıdaki adımları takip ediniz:</p>
            <p>1. Adım: Mevcut dolabınızda delik varsa, deliklerin merkez noktalarından (mm) cinsinden ölçü alınız.</p>
            <p>2. Adım: Tüm kulp modellerimiz verniklidir. Açık veya koyu renk tercihinizi lütfen bildiriniz.</p>
            <p>3. Adım: Dolap ve çekmece vidaları ücretsizdir. Mobilyanızın hazır mobilya mı yoksa marangoz yapımı mı olduğunu, mobilyanızda kaç kapak ve kaç çekmece bulunduğunu belirtiniz (marangoz yapımı mobilyalar genellikle 2 kapak-2 çekmece şeklindedir). Bu bilgiler, size uygun vida gönderimi yapabilmemiz için gereklidir (not: Marangoz mobilyalarında kapaklar çift MDF kalınlığında olmaktadır).</p>
            <p className='pt-4'>Bu üç adımı tamamladıktan sonra, bilgileri WhatsApp üzerinden bize iletmeniz yeterlidir.</p>
          </div>
          {activeImage && (
            <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-75 z-50" onClick={closeModal}>
              <div className="max-w-screen-md w-full max-h-screen-md p-4 bg-white rounded-lg overflow-hidden relative">
                <img src={activeImage} alt="Büyük Resim" className="w-full h-full object-contain" />
                <IoIosCloseCircle color="white" className='absolute top-4 right-4 h-16 w-16' onClick={closeModal}/>
              </div>
            </div>
          )}
      </div>
    );
  };
  

export default TekliAhsapKulp;
