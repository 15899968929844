import React from 'react';
import { FaInstagram, FaWhatsapp, FaRegQuestionCircle } from 'react-icons/fa';

const Header = () => {
  return (
    <div className="bg-white">
      {/* Üst Kısım */}
      <div className="flex justify-between items-center px-8 py-2 border-b shadow-md">
        <div className="text-sm">
          <a href="tel:905534561074">Detaylı Bilgi İçin +90 553 456 10 74</a>
        </div>
        <div className="flex space-x-4">
          <a
            href="https://www.instagram.com/lazzirhandle"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram size={20} color="purple" />
          </a>
          <a
            href="https://wa.me/905534561074"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaWhatsapp size={20} color="green" />
          </a>
        </div>
      </div>

      {/* Alt Kısım */}
      <div className="flex justify-center items-center py-6 border-b-2 border-amber-600">
        <div className="pl-16 flex items-center justify-start flex-1 pl-8 hidden md:flex">
          {/* Hidden on small screens, visible on medium and larger screens */}
          <a
            href="/hakkimizda"
            className="flex items-center space-x-2 text-base text-gray-700 hover:text-gray-900"
          >
            <FaRegQuestionCircle size={18} color="black" />
            <span className='font-semibold'>Hakkımızda</span>
          </a>
        </div>
        <div className="flex justify-center">
          <a href="/">
            <img src="/Logoclear.png" alt="Logo" className="md:h-24 h-16" />
          </a>
        </div>
        <div className="flex-1 hidden md:block"></div> {/* Empty div to keep the logo centered */}
      </div>
    </div>
  );
};

export default Header;
