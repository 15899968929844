import React from 'react';
import SimpleSlider from '../components/slider';
import FeaturedCategoryMain from '../components/onecikankategorilermain';
import FollowInstagramMain from '../components/followinstagrammain';
import HakkimizdaMain from '../components/hakkimizdamain';

function Main() {
  return (
    <>
     <SimpleSlider/>
     <FeaturedCategoryMain/>
     <FollowInstagramMain/>
     <HakkimizdaMain/>
    </>
  );
}

export default Main;
