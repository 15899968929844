import React from 'react';

function HakkimizdaMain() {
  return (
    <div className="bg-white text-black" id="drops">
      <div className="relative text-center py-4 text-black">
        <h1 className="md:text-4xl text-2xl font-medium relative">HAKKIMIZDA
          <div className="border-t border-4 border-amber-600 mt-2 mx-auto w-32"></div> {/* Border sadece yazının altında */}
        </h1>
      </div>
      <div className="flex flex-wrap justify-center content-center items-center gap-4 py-16 text-center px-6">
        <div className="w-[80%] flex flex-col md:flex-row border-4 md:p-16 p-8 bg-white rounded-2xl justify-between items-center my-4 shadow-lg">
            <div className="flex flex-col py-4">
              <span className="text-xl font-medium md:text-2xl lg:text-3xl border-b-2 border-black py-4">Lazzir Handle</span>
              <span className="text-md md:text-xl lg:text-xl py-4">Merhaba, biz Lazzir Handle olarak öncelikle kendimizi tanıtmak isteriz.

Manisa'nın Salihli ilçesinde bulunan üretim tesisimizde, ahşap ve deri olmak üzere 40 farklı kulp modelimiz bulunmaktadır. Ayrıca, 10 yılı aşkın süredir faaliyet gösterdiğimiz mobilya üretim tesisimizde özel üretim mobilyalar ve projeler yürütmekteyiz.</span>
            </div>
            <img alt="" src="/Logocircle.png" className="w-[400px] p-4"></img>
          </div>
      </div>
    </div>
  );
}

export default HakkimizdaMain;
