import React, { useState } from 'react';
import { Transition } from '@headlessui/react';
import { FaArrowLeft } from "react-icons/fa";
import { MdOutlineMenu } from "react-icons/md";

const Navbar = () => {
  const [dropdowns, setDropdowns] = useState({
    ahsapKulp: false,
    avize: false,
    tamamlayici: false,
    agacMasa: false,
  });
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleDropdown = (menu) => {
    setDropdowns((prev) => ({
      ...prev,
      [menu]: !prev[menu],
    }));
  };

  return (
    <nav className="bg-gray-100 border-b">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-20">
          {/* Desktop Menu */}
          <div className="hidden md:flex space-x-4 h-full">
            <div 
              className="relative h-full"
              onMouseEnter={() => toggleDropdown('ahsapKulp')} 
              onMouseLeave={() => toggleDropdown('ahsapKulp')}
            ><a href="/kategoriler/ahsap-kulplar"><button 
             className={`h-full px-3 flex items-center border-b-2 ${dropdowns.ahsapKulp ? 'border-amber-600' : 'border-transparent'} text-md font-medium text-gray-700 focus:outline-none`}
          >
            Ahşap Kulp
          </button></a>
              
              {dropdowns.ahsapKulp && (
                <div 
                  className="absolute left-0 w-48 shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10"
                >
                  <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <a href="/kategoriler/tekli-ahsap-kulplar" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Tekli Ahşap Kulp</a>
                    <a href="/kategoriler/standart-kulplar" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Standart Ahşap Kulplar</a>
                    <a href="/kategoriler/daire-kulplar" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Daire Kulplar</a>
                    <a href="/kategoriler/boy-kuplar" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Boy Kulplar</a>
                    <a href="/kategoriler/deri-kulplar" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Deri Kulplar</a>
                  </div>
                </div>
              )}
            </div>
            <a href="/kategoriler/mobilya-ayagi"><button className="h-full px-3 flex items-center border-b-2 border-transparent text-md font-medium text-gray-700 hover:border-amber-600">Mobilya Ayağı</button></a>
            <a href="/kategoriler/televizyon-uniteleri"><button className="h-full px-3 flex items-center border-b-2 border-transparent text-md font-medium text-gray-700 hover:border-amber-600">Televizyon Ünitesi</button></a>
            <div 
              className="relative h-full"
              onMouseEnter={() => toggleDropdown('avize')} 
              onMouseLeave={() => toggleDropdown('avize')}
            >
              <a href="/kategoriler/avizeler"><button 
                className={`h-full px-3 flex items-center border-b-2 ${dropdowns.avize ? 'border-amber-600' : 'border-transparent'} text-md font-medium text-gray-700 focus:outline-none`}
              >
                Avize
              </button></a>
              {dropdowns.avize && (
                <div 
                  className="absolute left-0 w-48 shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10"
                >
                  <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <a href="/kategoriler/hazeran-avizeler" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Hazeran Avize</a>
                    <a href="/kategoriler/ahsap-avizeler" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Ahşap Avize</a>
                  </div>
                </div>
              )}
            </div>
            <div 
              className="relative h-full"
              onMouseEnter={() => toggleDropdown('tamamlayici')} 
              onMouseLeave={() => toggleDropdown('tamamlayici')}
            >
              <a href="/kategoriler/tamamlayici-mobilyalar"><button 
                className={`h-full px-3 flex items-center border-b-2 ${dropdowns.tamamlayici ? 'border-amber-600' : 'border-transparent'} text-md font-medium text-gray-700 focus:outline-none`}
              >
                Tamamlayıcı Mobilya
              </button></a>
              {dropdowns.tamamlayici && (
                <div 
                  className="absolute left-0 w-48 shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10"
                >
                  <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <a href="/kategoriler/dresuarlar" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Dresuar</a>
                    <a href="/kategoriler/komidinler" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Komidin</a>
                    <a href="/kategoriler/konsollar" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Konsol</a>
                  </div>
                </div>
              )}
            </div>
            <div 
              className="relative h-full"
              onMouseEnter={() => toggleDropdown('agacMasa')} 
              onMouseLeave={() => toggleDropdown('agacMasa')}
            >
              <a href="/kategoriler/agac-masalar"><button 
                className={`h-full px-3 flex items-center border-b-2 ${dropdowns.agacMasa ? 'border-amber-600' : 'border-transparent'} text-md font-medium text-gray-700 focus:outline-none`}
              >
                Ağaç Masa
              </button></a>
              {dropdowns.agacMasa && (
                <div 
                  className="absolute left-0 w-48 shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10"
                >
                  <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <a href="/kategoriler/dogal-masalar" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Doğal Masa</a>
                    <a href="/kategoriler/orta-sehpalar" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Orta Sehpa</a>
                  </div>
                </div>
              )}
            </div>
            <a href="/kategoriler/montessori-yataklar"><button className="h-full px-3 flex items-center border-b-2 border-transparent text-md font-medium text-gray-700 hover:border-amber-600">Montessori Yatak</button></a>
            <a href="/kategoriler/hazeran-aksesuarlar"><button className="h-full px-3 flex items-center border-b-2 border-transparent text-md font-medium text-gray-700 hover:border-amber-600">Hazeran Aksesuarlar</button></a>
            <a href="/kategoriler/gardolaplar"><button className="h-full px-3 flex items-center border-b-2 border-transparent text-md font-medium text-gray-700 hover:border-amber-600">Gardolap</button></a>
            <a href="/kategoriler/projeler"><button className="h-full px-3 flex items-center border-b-2 border-transparent text-md font-medium text-gray-700 hover:border-amber-600">Projeler</button></a>
          </div>
          {/* Mobile Menu Button */}
          <div className="flex md:hidden items-center justify-between">
            <h1 className='text-xl font-semibold mr-4'>Ürünler ve Kategoriler</h1>
            <button 
              onClick={() => setIsMobileMenuOpen(true)} 
              className="text-gray-700 hover:text-gray-900 focus:outline-none ml-auto"
            >
              <MdOutlineMenu className="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <Transition
        show={isMobileMenuOpen}
        enter="transition-transform duration-300 ease-in-out"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transition-transform duration-300 ease-in-out"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div className="fixed inset-0 flex flex-col-reverse md:flex-row items-end md:items-center z-40">
          <div className="fixed inset-0 bg-black opacity-50" onClick={() => setIsMobileMenuOpen(false)}></div>
          <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white md:rounded-l-lg md:border-l md:border-t md:border-gray-200">
            <div className="absolute top-0 right-0 -mr-12 pt-2">
              <button 
                onClick={() => setIsMobileMenuOpen(false)} 
                className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:bg-gray-600"
              >
                                <FaArrowLeft className="h-6 w-6 text-white" />
              </button>
            </div>
            <div className="h-16 flex items-center px-4 bg-gray-100 border-b">
              <h2 className="text-lg font-medium text-gray-700">Ürünler ve Kategoriler</h2>
            </div>
            <div className="flex-1 h-0 overflow-y-auto">
              <nav className="px-2 py-4">
                <div 
                  className="relative"
                  onClick={() => toggleDropdown('ahsapKulp')}
                >
                  <button 
                    className={`px-4 py-2 w-full flex items-center border-b-2 ${dropdowns.ahsapKulp ? 'border-amber-600' : 'border-transparent'} text-base hover:bg-gray-200 font-medium text-gray-900 focus:outline-none`}
                  >
                    Ahşap Kulp
                  </button>
                  {dropdowns.ahsapKulp && (
                    <div 
                      className="absolute top-full left-0 w-full shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10"
                    >
                      <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        <a href="/kategoriler/tekli-ahsap-kulplar" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Tekli Ahşap Kulp</a>
                        <a href="/kategoriler/standart-kulplar" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Standart Ahşap Kulplar</a>
                        <a href="/kategoriler/deri-kulplar" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Daire Kulplar</a>
                        <a href="/kategoriler/boy-kuplar" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Boy Kulplar</a>
                        <a href="/kategoriler/deri-kuplar" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Deri Kulplar</a>
                      </div>
                    </div>
                  )}
                </div>
                <a href="/kategoriler/mobilya-ayagi" className="block px-4 py-2 text-base font-medium text-gray-900 hover:bg-gray-200">Mobilya Ayağı</a>
                <a href="/kategoriler/televizyon-uniteleri" className="block px-4 py-2 text-base font-medium text-gray-900 hover:bg-gray-200">Televizyon Ünitesi</a>
                <div 
                  className="relative"
                  onClick={() => toggleDropdown('avize')}
                >
                  <button 
                    className={`px-4 py-2 w-full flex items-center border-b-2 ${dropdowns.avize ? 'border-amber-600' : 'border-transparent'} text-base hover:bg-gray-200 font-medium text-gray-900 focus:outline-none`}
                  >
                    Avize
                  </button>
                  {dropdowns.avize && (
                    <div 
                      className="absolute top-full left-0 w-full shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10"
                    >
                      <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        <a href="/kategoriler/hazeran-avizeler" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Hazeran Avize</a>
                        <a href="/kategoriler/ahsap-avizeler" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Ahşap Avize</a>
                      </div>
                    </div>
                  )}
                </div>
                <div 
                  className="relative"
                  onClick={() => toggleDropdown('tamamlayici')}
                >
                  <button 
                    className={`px-4 py-2 w-full flex items-center border-b-2 ${dropdowns.tamamlayici ? 'border-amber-600' : 'border-transparent'} text-base hover:bg-gray-200 font-medium text-gray-900 focus:outline-none`}
                  >
                    Tamamlayıcı Mobilya
                  </button>
                  {dropdowns.tamamlayici && (
                    <div 
                      className="absolute top-full left-0 w-full shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10"
                    >
                      <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        <a href="/kategoriler/dresuarlar" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Dresuar</a>
                        <a href="/kategoriler/komidinler" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Komidin</a>
                        <a href="/kategoriler/konsollar" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Konsol</a>
                      </div>
                    </div>
                  )}
                </div>
                <div 
                  className="relative"
                  onClick={() => toggleDropdown('agacMasa')}
                >
                  <button 
                    className={`px-4 py-2 w-full flex items-center border-b-2 ${dropdowns.agacMasa ? 'border-amber-600' : 'border-transparent'} text-base hover:bg-gray-200 font-medium text-gray-900 focus:outline-none`}
                  >
                    Ağaç Masa
                  </button>
                  {dropdowns.agacMasa && (
                    <div 
                      className="absolute top-full left-0 w-full shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10"
                    >
                      <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        <a href="/kategoriler/dogal-masalar" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Doğal Masa</a>
                        <a href="/kategoriler/orta-sehpalar" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Orta Sehpa</a>
                      </div>
                    </div>
                  )}
                </div>
                <a href="/kategoriler/montessori-yataklar" className="block px-4 py-2 text-base font-medium text-gray-900 hover:bg-gray-200">Montessori Yatak</a>
                <a href="/kategoriler/hazeran-aksesuarlar" className="block px-4 py-2 text-base font-medium text-gray-900 hover:bg-gray-200">Hazeran Aksesuarlar</a>
                <a href="/kategoriler/gardolaplar" className="block px-4 py-2 text-base font-medium text-gray-900 hover:bg-gray-200">Gardolap</a>
                <a href="/kategoriler/projeler" className="block px-4 py-2 text-base font-medium text-gray-900 hover:bg-gray-200">Projeler</a>
              </nav>
            </div>
          </div>
        </div>
      </Transition>
    </nav>
  );
}

export default Navbar;

