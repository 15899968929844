import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React from 'react';
import Header from "./components/header";
import Navbar from "./components/navbar";
import SimpleSlider from "./components/slider";
import FeaturedCategoryMain from "./components/onecikankategorilermain";
import FollowInstagramMain from "./components/followinstagrammain";
import HakkimizdaMain from "./components/hakkimizdamain";
import Footer from "./components/footer";
import MobilyaAyagi from "./pages/mobilyaayagi";
import TelevizyonUniteleri from "./pages/televizyonuniteleri";
import Avize from "./pages/avize";
import TamamlayiciMobilya from "./pages/tamamlayicimobilya";
import HazeranAvize from "./pages/hazeranavize";
import AhsapAvize from "./pages/ahsapavize";
import Dresuarlar from "./pages/dresuar";
import Komidinler from "./pages/komidin";
import Konsollar from "./pages/konsollar";
import AgacMasa from "./pages/agacmasa";
import DogalMasa from "./pages/dogalmasa";
import OrtaSehpa from "./pages/ortasehpa";
import MontessoriYatak from "./pages/montessoriyatak";
import HazeranAksesuar from "./pages/hazeranaksesuar";
import Gardolap from "./pages/gardolap";
import TekliAhsapKulp from "./pages/tekliahsapkulp";
import StandartKulplar from "./pages/standartkulplar";
import DeriKulplar from "./pages/derikulplar";
import DaireKulplar from "./pages/dairekulplar";
import BoyKulplar from "./pages/boykulplar";
import AhsapKulplar from "./pages/ahsapkulplar";
import Main from './pages/main';
import Projeler from './pages/projeler';
import Hakkimizda from './pages/hakkimizda';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Navbar/>
        <Routes>
          <Route exact path="/" element={<Main/>}/>
          <Route exact path="/kategoriler/mobilya-ayagi" element={<MobilyaAyagi/>}/>
          <Route exact path="/kategoriler/televizyon-uniteleri" element={<TelevizyonUniteleri/>}/>
          <Route exact path="/kategoriler/avizeler" element={<Avize/>}/>
          <Route exact path="/kategoriler/tamamlayici-mobilyalar" element={<TamamlayiciMobilya/>}/>
          <Route exact path="/kategoriler/hazeran-avizeler" element={<HazeranAvize/>}/>
          <Route exact path="/kategoriler/ahsap-avizeler" element={<AhsapAvize/>}/>
          <Route exact path="/kategoriler/dresuarlar" element={<Dresuarlar/>}/>
          <Route exact path="/kategoriler/komidinler" element={<Komidinler/>}/>
          <Route exact path="/kategoriler/konsollar" element={<Konsollar/>}/>
          <Route exact path="/kategoriler/agac-masalar" element={<AgacMasa/>}/>
          <Route exact path="/kategoriler/dogal-masalar" element={<DogalMasa/>}/>
          <Route exact path="/kategoriler/orta-sehpalar" element={<OrtaSehpa/>}/>
          <Route exact path="/kategoriler/montessori-yataklar" element={<MontessoriYatak/>}/>
          <Route exact path="/kategoriler/hazeran-aksesuarlar" element={<HazeranAksesuar/>}/>
          <Route exact path="/kategoriler/gardolaplar" element={<Gardolap/>}/>
          <Route exact path="/kategoriler/tekli-ahsap-kulplar" element={<TekliAhsapKulp/>}/>
          <Route exact path="/kategoriler/standart-kulplar" element={<StandartKulplar/>}/>
          <Route exact path="/kategoriler/deri-kulplar" element={<DeriKulplar/>}/>
          <Route exact path="/kategoriler/daire-kulplar" element={<DaireKulplar/>}/>
          <Route exact path="/kategoriler/boy-kuplar" element={<BoyKulplar/>}/>
          <Route exact path="/kategoriler/ahsap-kulplar" element={<AhsapKulplar/>}/>
          <Route exact path="/kategoriler/projeler" element={<Projeler/>}/>
          <Route exact path="/hakkimizda" element={<Hakkimizda/>}/>
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
