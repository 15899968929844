import React from 'react';

function FollowInstagramMain() {
  return (
    <div className="bg-orange-100 text-black" id="drops">
      
      <div className="flex flex-wrap justify-center content-center items-center gap-4 py-16 text-center px-6">
        <div className="relative text-center py-4 text-black">
          <h1 className="md:text-4xl text-2xl font-medium relative">Bizi Instagramdan takip et!
            <div className="border-t border-4 border-amber-600 mt-2 mx-auto w-72"></div> {/* Border sadece yazının altında */}
          </h1>
          <button className="w-40 h-16 bg-transparent border-2 border-amber-600 shadow-lg text-2xl font-freeman text-black hover:text-white hover:bg-amber-600 mt-4"><a href="https://www.instagram.com/lazzirhandle" target="_blank" rel="noopener noreferrer">TAKİP ET</a></button>
        </div>
        <div className="md:w-[450px] md:h-[550px] w-[225px] h-[275px] bg-gradient-to-br bg-white shadow-2xl mr-16 md:mr-0 hover:opacity-50 cursor-pointer hover:text-white">
        <a href="https://www.instagram.com/lazzirhandle" target="_blank" rel="noopener noreferrer"><div className="flex justify-center content-center items-center w-full md:h-[550px] h-[275px]">
            <img alt="" src="/lazzirinsta1.png" className="h-full w-full"></img>
          </div></a>
        </div>
        <div className="md:w-[450px] md:h-[550px] w-[225px] h-[275px] bg-gradient-to-br bg-white shadow-2xl md:mt-24 ml-16 md:ml-0 hover:text-white hover:opacity-50 cursor-pointer">
        <a href="https://www.instagram.com/lazzirhandle" target="_blank" rel="noopener noreferrer">
          <div className="flex justify-center content-center items-center w-full md:h-[550px] h-[275px]">
            <img alt="" src="/lazzirinsta2.png" className="h-full w-full"></img>
          </div></a>
        </div>
      </div>
    </div>
  );
}

export default FollowInstagramMain;
