import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { IoIosCloseCircle } from "react-icons/io";

const products = [
  {
    id: 1,
    type: 'carousel',
    name: 'Ahşap Sehpa 1',
    images: [
      '/kategoriler/agacmasa/ahsapsehpa/ahsapsehpa1.PNG'
    ],
    detailsUrl: 'https://wa.me/905534561074'
  },
  {
    id: 2,
    type: 'carousel',
    name: 'Ahşap Sehpa 2',
    images: [
      '/kategoriler/agacmasa/ahsapsehpa/ahsapsehpa2.PNG',
      '/kategoriler/agacmasa/ahsapsehpa/ahsapsehpa2.PNG'
    ],
    detailsUrl: 'https://wa.me/905534561074'
  },
];

const OrtaSehpa = () => {
    const [activeImage, setActiveImage] = useState(null);

    const openModal = (image) => {
      setActiveImage(image);
    };
  
    const closeModal = () => {
      setActiveImage(null);
    };
  
    const getDetailsUrl = (product) => {
      const message = `Merhaba, "${product.name}" isimli ürününüz için size ulaşmak istiyorum.`;
      return `https://wa.me/905534561074?text=${encodeURIComponent(message)}`;
    };
    
    return (
      <div className='min-h-[70vh]'>
          <h1 className="w-full text-center text-2xl font-bold my-4">Orta Sehpalar</h1>
          <div className="flex flex-wrap justify-center gap-4 p-4 ">
          {products.map(product => (
          <div key={product.id} className="w-64 bg-white h-fit shadow-lg rounded-lg overflow-hidden">
            {product.type === 'single' ? (
              <div>
                <img src={product.images[0]} alt={product.name} className="w-full h-48 object-cover" />
                <div className="p-4">
                  <h2 className="text-lg font-semibold">{product.name}</h2>
                  <a href={getDetailsUrl(product)} target="_blank" rel="noopener noreferrer" className="mt-2 block text-center bg-green-500 text-white py-2 rounded">
                    Detaylı Bilgi için WhatsApp
                  </a>
                </div>
              </div>
            ) : (
              <div>
                <Swiper spaceBetween={50} slidesPerView={1} pagination={{ clickable: true }}>
                  {product.images.map((image, index) => (
                    <SwiperSlide key={index}>
                      <div className="relative" onClick={() => openModal(image)}>
                        <img src={image} alt={product.name} className="w-full h-48 object-cover cursor-pointer" />
                        <div className="absolute top-2 left-2 bg-black bg-opacity-50 text-white px-2 py-1 rounded-full">
                          {index + 1} / {product.images.length}
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="p-4">
                  <h2 className="text-lg font-semibold">{product.name}</h2>
                  <a href={getDetailsUrl(product)} target="_blank" rel="noopener noreferrer" className="mt-2 block text-center bg-green-500 text-white py-2 rounded">
                    Detaylı Bilgi için WhatsApp
                  </a>
                </div>
              </div>
            )}
          </div>
        ))}
          </div>
          {activeImage && (
            <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-75 z-50" onClick={closeModal}>
              <div className="max-w-screen-md w-full max-h-screen-md p-4 bg-white rounded-lg overflow-hidden relative">
                <img src={activeImage} alt="Büyük Resim" className="w-full h-full object-contain" />
                <IoIosCloseCircle color="white" className='absolute top-4 right-4 h-16 w-16' onClick={closeModal}/>
              </div>
            </div>
          )}
      </div>
    );
  };
  

export default OrtaSehpa;
